import React from 'react'

function About() {
  return (
    <div>
      this is ABOUT US PAGE
    </div>
  )
}

export default About
