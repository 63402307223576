import React from 'react'

function Contact() {
  return (
    <div>
      This is the contact page
    </div>
  )
}

export default Contact
